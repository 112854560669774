import Parse from "parse";














export class TenantMenu extends Parse.Object {
  constructor(data) {
    super("APP_Menu", data );
  }
}

Parse.Object.registerSubclass("APP_Menu", TenantMenu);

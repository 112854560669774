import Parse from "parse";















export class TenantConfig extends Parse.Object {
  constructor(data) {
    super("OD3_TenantMeta", data );
  }
}

Parse.Object.registerSubclass("OD3_TenantMeta", TenantConfig);

const _jsxFileName = "/Users/nico/Desktop/basic-admin/src/js/components/AdminRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";


import { useTranslation, AdminLayout, useNavigation } from "@opendash/core";

import { useNavigate } from "react-router";

import {
  Container,
  ContainerInner,
  Element,
} from "@opendash/core/dist/features/core/components/OpenDashFrontpage.layout";

export const AdminRoute = React.memo(function AdminRoute() {
  const t = useTranslation();
  const navigate = useNavigate();

  const [allGroups, allItems] = useNavigation("sidebar");

  const group = allGroups.find((group) => group.label === "app:admin.title");
  const items = allItems
    .filter((item) => item.group === _optionalChain([group, 'optionalAccess', _ => _.id]))
    .filter((item) => item.link)
    .filter((item) => item.icon)
    .map((item) => ({
      ...item,
      onClick: () => {
        if (item.link) {
          navigate(item.link);
        }
      },
    }));

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        , React.createElement(ContainerInner, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
          , items.map((e) => (
            React.createElement(Element, { key: e.id, ...e, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} )
          ))

          , items.length === 0 && t("opendash:frontpage.empty")
        )
      )
    )
  );
});

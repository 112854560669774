import Parse from "parse";

Parse.initialize("openincapp");
Parse.serverURL = "https://parse-basic-app.apps.openinc.dev/parse";

export const ParseAdminConfig = [
  {
    className: "_User",
    relation: ["username"],
    columns: ["name", "username", "email", "tenant", "tenantAdmin"],
    create_fields: [
      "name",
      "username",
      "email",
      "tenant",
      "tenantAdmin",
      "password",
    ],
    edit_fields: [
      "name",
      "username",
      "email",
      "tenant",
      "tenantAdmin",
      "password",
    ],
  },

  {
    className: "OD3_Tenant",
    relation: ["label"],
    columns: ["label", "tenantConfig"],
    create_fields: ["label"],
    edit_fields: ["label"],
  },
  {
    className: "OD3_TenantMeta",
    relation: [],
    columns: ["title", "logo", "primaryColor", "secondaryColor"],
    create_fields: ["title", "logo", "primaryColor", "secondaryColor"],
    edit_fields: ["title", "logo", "primaryColor", "secondaryColor"],
  },
  {
    className: "OD_TenantTrustedDomain",
    relation: ["host"],
    columns: ["tenant", "host"],
    create_fields: ["tenant", "host"],
    edit_fields: ["tenant", "host"],
  },
  {
    className: "APP_Menu",
    relation: ["menuItemLabel"],
    columns: [
      "menuItemIcon",
      "menuItemUrl",
      "menuItemLabel",
      "tenant",
      "order",
    ],
    create_fields: [
      "menuItemIcon",
      "menuItemUrl",
      "menuItemLabel",
      "tenant",
      "order",
    ],
    edit_fields: [
      "menuItemIcon",
      "menuItemUrl",
      "menuItemLabel",
      "tenant",
      "order",
    ],
  },
];

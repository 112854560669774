const _jsxFileName = "/Users/nico/Desktop/basic-admin/src/js/components/TenantConfigRoute.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React from "react";
import Parse from "parse";

import {
  AdminLayout,
  usePermission,
  usePromise,
  useTranslation,
} from "@opendash/core";

import { ParseObject } from "@opendash/plugin-parse-admin";

import { TenantConfig } from "../types";

import { message } from "antd";





const fields = {
  appearance: ["title", "logo", "primaryColor", "secondaryColor"],
  navigation: [
    "menuItemLabel",
    "menuItemUrl",
    "menuItemIcon",
    "imprintUrl",
    "tosUrl",
    "privacyUrl",
  ],
};

export const TenantConfigRoute = React.memo(function TenantConfigRoute({
  type,
}) {
  const t = useTranslation();

  const isTenantAdmin = usePermission("parse-admin");

  const [config, error, loading] = usePromise(async () => {
    if (!isTenantAdmin) {
      message.error(t("app:config.missing_permission_message"));
      return undefined;
    }

    const currentUserTenant =
      _optionalChain([Parse, 'access', _ => _.User, 'access', _2 => _2.current, 'call', _3 => _3(), 'optionalAccess', _4 => _4.get, 'call', _5 => _5("tenant")]);

    if (!currentUserTenant) {
      message.error(t("app:config.missing_permission_message"));
      return undefined;
    }

    const config = await new Parse.Query(TenantConfig)
      .equalTo("tenant", currentUserTenant)
      .first();

    if (!config) {
      message.error(t("app:config.missing_permission_message"));
      return undefined;
    }

    return config;
  }, [isTenantAdmin]);

  return (
    React.createElement(AdminLayout, { contentPadding: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      , React.createElement(ParseObject, {
        object: config,
        fields: fields[type],
        editFields: fields[type],
        disableDelete: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
      )
    )
  );
});

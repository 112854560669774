import Parse from "parse";












export class TenantDomain extends Parse.Object {
  constructor(data) {
    super("OD3_TenantTrustedDomain", data );
  }
}

Parse.Object.registerSubclass("OD3_TenantTrustedDomain", TenantDomain);

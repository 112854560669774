// @ts-check

import "antd/dist/antd.min.css";

import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";

import { ParsePlugin } from "@opendash/plugin-parse";
import { ParseAdminPlugin } from "@opendash/plugin-parse-admin";

import { ParseAdminConfig } from "./parse.config";
import { TenantConfigRoute } from "./components/TenantConfigRoute";
import { TenantMenuRoute } from "./components/TenantMenuRoute";
import { AdminRoute } from "./components/AdminRoute";

init("opendash", async (factory) => {
  // Set document title
  try {
    window.document.title = "open.INC Basic App";
  } catch (error) {}

  // Logo:

  //factory.ui.setLogoImage(require("../assets/logo.png"));

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:

  factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch", "en", true);

  factory.registerTranslationResolver(
    "en",
    "app",
    () => import("./translations/en/app.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    () => import("./translations/en/parse-custom.json")
  );

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerTranslationResolver(
    "de",
    "app",
    () => import("./translations/de/app.json")
  );

  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    () => import("./translations/de/parse-custom.json")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(new ParsePlugin({ useLiveQueries: false }));
  await factory.use(new ParseAdminPlugin(ParseAdminConfig));

  // Routes

  factory.registerRoute({
    path: "/admin/openinc",
    component: async () => ({ default: AdminRoute }),
  });

  factory.registerRoute({
    path: "/admin/openinc/config-appearance",
    component: async () => ({ default: TenantConfigRoute }),
    props: {
      type: "appearance",
    },
  });

  factory.registerRoute({
    path: "/admin/openinc/menu",
    component: async () => ({ default: TenantMenuRoute }),
    props: {},
  });

  factory.registerRoute({
    path: "/admin/openinc/config-navigation",
    component: async () => ({ default: TenantConfigRoute }),
    props: {
      type: "navigation",
    },
  });

  factory.registerRoute({
    path: "/admin/openinc/config-contact",
    component: async () => ({ default: TenantConfigRoute }),
    props: {
      type: "contact",
    },
  });
}).then((app) => {});
